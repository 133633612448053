import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { IssueId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const useUpdateIssueNote = (
  options?: UseMutationOptions<
    CommonSuccess,
    GenericFailure | Error,
    { id: IssueId; noteId: string; env: Environment; note: string }
  >
) =>
  useEitherMutation(
    ({ id, noteId, env, note }) => services2.audienceSupportService.updateIssueNote(id, noteId, note, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
